import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { addToProjectsSuccess, projectsReadFailure, addRangeToProjects, projectUpdateSuccess, deleteProjectSuccess } from './actions';
import { authEndLoading } from '../auth/actions';

export function* getList() {
  try {
    const response = yield call(api.get, 'projects');

    if (!response) throw 'Erro a listar os dados';
    if (!response.data) throw 'Erro a listar os dados';

    const result = response.data;

    if (!result.success) throw result.error;

    // para limpar a lista
    yield put(projectsReadFailure());

    yield put(addRangeToProjects(result.data));
  }
  catch (err) {
    yield put(projectsReadFailure());
  }
}

export function* addProject({ payload }) {
  try {
    const { title, start_date, end_date, description, color, repo_name } = payload;

    var data = new FormData();

    data.append('title', title);
    data.append('start_date', (!start_date ? '' : start_date));
    data.append('end_date', (!end_date ? '' : end_date));
    data.append('description', (!description ? '' : description));
    data.append('color', (!color ? '' : color));
    data.append('repo_name', (!repo_name ? '' : repo_name));

    const response = yield call(api.post, 'projects/create', data);

    if (!response) throw 'Falha ao cadastrar, tente novamente mais tarde';

    if (!response.data) throw 'Falha ao cadastrar, verifique seus dados';

    const result = response.data;

    if (!result.success) throw result.error;

    yield put(addToProjectsSuccess(result.data));

    history.push(`/projects/${result.data.id}`);
  }
  catch (err) {
    toast.error(`${err}`);
  }
  finally {
    yield put(authEndLoading());
  }
}

export function* updateProject({ payload }) {
  try {
    const { id, title, start_date, end_date, description, color, repo_name, handleToggleModal, showToast } = payload;

    var data = new FormData();

    data.append('title', title);
    data.append('start_date', (!start_date ? '' : start_date));
    data.append('end_date', (!end_date ? '' : end_date));
    data.append('description', (!description ? '' : description));
    data.append('color', (!color ? '' : color));
    data.append('repo_name', (!repo_name ? '' : repo_name));

    const response = yield call(api.post, `projects/update/${id}`, data);

    if (!response) throw 'Falha ao cadastrar, tente novamente mais tarde';

    if (!response.data) throw 'Falha ao cadastrar, verifique seus dados';

    const result = response.data;

    if (!result.success) throw result.error;

    yield put(projectUpdateSuccess(result.data));

    handleToggleModal();

    history.push(`/projects/${result.data.id}`);

    if (showToast) toast.success('Projeto atualizado com sucesso!');
  }
  catch (err) {
    toast.error(`${err}`);
  }
  finally {
    yield put(authEndLoading());
  }
}

export function* deleteProject({ id }) {
  try {
    const response = yield call(api.delete, `projects/delete/${id}`);

    if (!response) throw 'Falha ao excluir, tente novamente mais tarde';

    if (!response.data) throw 'Falha ao excluir, verifique seus dados';

    const result = response.data;

    if (!result.success) throw result.error;

    yield put(deleteProjectSuccess(id));

    history.push('/projects');
  }
  catch (err) {
    toast.error(`${err}`);
  }
  finally {
    yield put(authEndLoading());
  }
}

export default all([
  takeLatest('@project/READ', getList),
  takeLatest('@project/ADD_REQUEST', addProject),
  takeLatest('@project/UPDATE_REQUEST', updateProject),
  takeLatest('@project/DELETE_REQUEST', deleteProject),
]);
