import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '~/services/api';

import Modal from '~/components/Modal';

import { Container } from './styles';

const schema = Yup.object().shape({
  title: Yup.string().required('Título obrigatório'),
});

export default function Create({ isOpen, loading, onClose, onRefresh, projectId }) {
  const [group, setGroup] = useState(true);

  async function onSubmit({ title }) {
    try {
      if (group === "false") throw 'Somente grupos estão sendo cadastrados';

      var data = new FormData();

      data.append('project_id', projectId);
      data.append('title', title);

      const response = await api.post(`projectgroup/create/`, data);

      const result = response.data;

      if (!result.success) throw result.error;

      onRefresh();
    }
    catch (err) {
      toast.error(`${err}`);
    }
  }

  return (
    <Modal show={isOpen}
      onClose={onClose}
      title="Criar tarefa">
      <Container>
        <Form schema={schema} onSubmit={onSubmit}>
          <div className="row ml-0 mr-0">
            <div className="form-group col-12 col-lg-4">
              <label htmlFor="title">Grupo?</label>
              <select name="group" className="form-control" onChange={e => setGroup(e.target.value)}>
                <option value="true" selected={group && 'selected'}>Sim</option>
                <option value="false" selected={!group && 'selected'}>Não</option>
              </select>
            </div>

            <div className="form-group col-12 col-lg-8">
              <label htmlFor="title">Título</label>
              <Input name="title" type="text" className="form-control" />
            </div>

            <div className="form-group col-12">
              <button className="btn btn-primary col-12" type="submit" disabled={loading}>{loading ? 'Carregando...' : 'Salvar'}</button>
            </div>
          </div>
        </Form>
      </Container>
    </Modal>
  );
};
