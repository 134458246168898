import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signUpRequest } from '~/store/modules/auth/actions';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  emailConfirm: Yup.string().oneOf([Yup.ref('email')], 'Os e-mails não conferem'),
  password: Yup.string().min(6, 'Mínimo de 6 caracteres').required('Senha obrigatória'),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password')], 'As senhas não conferem'),
});

export default function SignUp() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ name, email, emailConfirm, password, passwordConfirm }) {
    dispatch(signUpRequest(name, email, emailConfirm, password, passwordConfirm));
  }

  return (
    <>
      <h1>Station Up</h1>

      <Form schema={schema} onSubmit={handleSubmit}>
        <Input name="name" placeholder="Nome completo" />
        <Input name="email" type="email" placeholder="Seu e-mail" />
        <Input name="emailConfirm" type="email" placeholder="Confirme seu e-mail" />
        <Input name="password" type="password" placeholder="Sua senha" />
        <Input name="passwordConfirm" type="password" placeholder="Confirme senha" />

        <button type="submit">{loading ? 'Carregando...' : 'Criar conta'}</button>
        <Link to="/" >Já tenho login</Link>
      </Form>
    </>
  );
}
