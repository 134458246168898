import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.4);
  overflow: hidden;
  overflow-y: scroll;
  align-items: center;
  vertical-align: middle;
  z-index: 7777;

  .xl {
    max-width: 1140px;

    @media (max-width: 992px) {
        max-width: 800px;
    }

    @media (max-width: 768px) {
        max-width: 500px;
    }
  }

  .lg {
    max-width: 800px;

    @media (max-width: 768px) {
        max-width: 500px;
    }
  }

  .md {
    max-width: 500px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  z-index: 7788;

  form {
    width: 100%;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  color: #fff;
  background-color: #4b646f;
  border-bottom: 1px solid #ccc;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;

  h1 {
    padding: 2px;
    font-size: 18px;
  }

  svg {
    font-size: 18px;
    cursor: pointer;
    color: #eee;
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  padding: 15px;
  padding-top: 25px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #fff;
`;

export const ModalFooter = styled.div`
  width: 100%;
  padding: 15px;
`;
