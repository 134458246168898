import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Textarea } from '@rocketseat/unform';
import * as Yup from 'yup';

import { MdClose } from 'react-icons/md';

import history from '~/services/history';

import { authStartLoading } from '~/store/modules/auth/actions';
import { projectAddRequest } from '~/store/modules/projects/actions';

import { Container, Header, Buttons, Body, FormGroup } from './styles';

const schema = Yup.object().shape({
  title: Yup.string().required('Título obrigatório'),
  start_date: Yup.string(),
  end_date: Yup.string(),
  description: Yup.string(),
  color: Yup.string(),
  repo_name: Yup.string(),
});

export default function ProjectCreate() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ title, start_date, end_date, description, color, repo_name }) {
    dispatch(authStartLoading());
    dispatch(projectAddRequest(title, start_date, end_date, description, color, repo_name));
  }

  return (
    <Container>
      <Header>
        <h1>Adicionar um projeto</h1>
        <Buttons>
          <MdClose onClick={() => history.goBack()} title="Fechar" />
        </Buttons>
      </Header>
      <Body>

        <Form schema={schema} onSubmit={handleSubmit}>

          <FormGroup>
            <label for="title">Nome do projeto</label>
            <Input id="title" name="title" placeholder="Título do projeto" />
          </FormGroup>

          <FormGroup>
            <label for="description">Descrição</label>
            <Textarea id="description" name="description" placeholder="Descrição do projeto" rows={10} />
          </FormGroup>

          <FormGroup>
            <button className="btn" type="submit" disabled={loading}>{loading ? 'Carregando...' : 'Adicionar'}</button>
          </FormGroup>
        </Form>
      </Body>
    </Container>
  );
}
