import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';
import { promisify } from 'util';

import history from '~/services/history';
import api from '~/services/api';

import authConfig from '~/config/authConfig';

import { signInRequest, signInSuccess, signFailure, signOut } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    var data = new FormData();

    data.append('email', email);
    data.append('password', password);

    const response = yield call(api.post, 'login', data);

    if (!response) throw 'Falha na autenticação, tente novamente mais tarde';

    if (!response.data) throw 'Falha na autenticação, verifique seus dados';

    const result = response.data;

    if (!result.success) throw result.error;

    const { token } = result.data;

    const user = {
      id: result.data.id,
      name: result.data.name,
      email: result.data.email,
      active: result.data.active
    };

    if (!user.active) throw 'Usuario inativo';

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    history.push('/dashboard');
  }
  catch (err) {
    toast.error(`${err}`);
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, emailConfirm, password, passwordConfirm } = payload;

    var data = new FormData();

    data.append('name', name);
    data.append('email', email);
    data.append('emailConfirm', emailConfirm);
    data.append('password', password);
    data.append('passwordConfirm', passwordConfirm);

    const response = yield call(api.post, 'newaccount', data);

    if (!response) throw 'Falha ao cadastrar, tente novamente mais tarde';

    if (!response.data) throw 'Falha ao cadastrar, verifique seus dados';

    const result = response.data;

    if (!result.success) throw result.error;

    const { token } = result.data;

    toast.success('Cadastro efetuado com sucesso!');

    yield put(signInRequest(email, password));
  }
  catch (err) {
    toast.error(`${err}`);
    yield put(signFailure());
  }
}

export function* setToken({ payload }) {
  try {
    if (!payload) throw 'Falha ao recuperar os dados';

    const { token } = payload.auth;

    const user = yield promisify(jwt.verify)(token, authConfig.secret);

    if (payload.user.profile.id !== user.id) throw 'Usuário inválido';

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }
  catch (ex) {
    yield put(signOut());
    history.push('/');
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
]);