export function addToProjectsSuccess(project) {
  return {
    type: '@project/ADD_SUCCESS',
    project,
  };
}

export function projectsRead() {
  return {
    type: '@project/READ',
  };
}

export function projectsReadFailure() {
  return {
    type: '@project/READ_ERROR',
  };
}

export function projectAddRequest(title, start_date, end_date, description, color, repo_name) {
  return {
    type: '@project/ADD_REQUEST',
    payload: { title, start_date, end_date, description, color, repo_name },
  };
}

export function addRangeToProjects(projects) {
  return {
    type: '@project/ADD_RANGE',
    projects,
  };
}

export function projectUpdateRequest(id, title, start_date, end_date, description, color, repo_name, handleToggleModal, showToast = true) {
  return {
    type: '@project/UPDATE_REQUEST',
    payload: { id, title, start_date, end_date, description, color, repo_name, handleToggleModal, showToast },
  };
}

export function projectUpdateSuccess(project) {
  return {
    type: '@project/UPDATE_SUCCESS',
    project,
  };
}

export function deleteProjectRequest(id) {
  return {
    type: '@project/DELETE_REQUEST',
    id,
  };
}

export function deleteProjectSuccess(id) {
  return {
    type: '@project/REMOVE',
    id,
  };
}
