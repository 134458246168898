import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8000'
  : 'https://app.stationup.com.br/api';

const api = axios.create({
  baseURL: baseURL,
});

export default api;
