import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  height: 100%;
  background-color: #fff;
`;

export const Content = styled.div`
  padding: 0;
  margin-left: 250px;
  text-align: center;

  @media (max-width: 992px) {
    padding: 15px;
    margin: 0;
  }

  input, textarea, select {
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #4b646f;
    font-size: 12px;
    padding: 0 10px;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  label {
    font-size: 12px;
    color: #4b646f;
  }

  input, select {
    height: 34px;
  }

  select {
    
  }

  textarea {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;

    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
      font-size: 12px;
    }

    button {
      &.btn {
        margin: 5px 0 0;
        height: 44px;
        background: #3b9eff;
        font-weight: bold;
        color: #fff;
        border: 0;
        border-radius: 4px;
        font-size: 16px;
        transition: background 0.2s;

        &:hover {
          background: ${darken(0.04, '#3b9eff')};
        }
      }
    }

    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
