import produce from 'immer';

const INITIAL_STATE = [];

export default function projects(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@project/ADD_SUCCESS':
      return produce(state, draft => {

        const { project } = action;
        const projectIndex = draft.findIndex(p => p.id === project.id);

        if (!(projectIndex >= 0)) {
          draft.unshift(project);
        }
      });

    case '@project/REMOVE':
      return produce(state, draft => {

        const projectIndex = draft.findIndex(p => Number(p.id) === Number(action.id));

        console.tron.log(projectIndex);

        if (projectIndex >= 0) {
          draft.splice(projectIndex, 1);
        }

      });

    case '@project/READ_ERROR': {
      return state = [];
      break;
    }

    case '@project/ADD_RANGE':
      return produce(state, draft => {

        const { projects } = action;

        projects.map(project => {
          const projectIndex = draft.findIndex(p => p.id === project.id);

          if (!(projectIndex >= 0)) {
            draft.push(project);
          }
        });
      });

    case '@project/UPDATE_SUCCESS':
      return produce(state, draft => {

        const { project } = action;
        const projectIndex = draft.findIndex(p => p.id === project.id);

        if (projectIndex >= 0) {
          draft.splice(projectIndex, 1);
          draft.unshift(project);
        }
      });

    default:
      return state;
  }
}
