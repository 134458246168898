import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { Form, Input, Textarea } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { MdAdd, MdClose, MdEdit, MdDelete } from 'react-icons/md';
import { FaCircle, FaBorderStyle } from 'react-icons/fa';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';

import history from '~/services/history';
import api from '~/services/api';

import Modal from '~/components/Modal';
import ProjectGroupCreate from '~/pages/ProjectGroup/Create';

import { authStartLoading } from '~/store/modules/auth/actions';
import { projectUpdateRequest, deleteProjectRequest } from '~/store/modules/projects/actions';

import { Container, Loading, Header, Body, Buttons, Details, TaskList, ModalContent, FormGroup, ColorList, ColorListContainer } from './styles';

const schema = Yup.object().shape({
  id: Yup.number(),
  title: Yup.string().required('Título obrigatório'),
  start_date: Yup.string(),
  end_date: Yup.string(),
  description: Yup.string(),
  color: Yup.string(),
  repo_name: Yup.string(),
});

registerLocale('pt', pt);

export default function ProjectDetail({ match }) {
  const id = match.params.id;

  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);
  const [endDate, setEndDate] = useState();

  const [loadingProject, setloadingProject] = useState(true);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isColorOpen, setisColorOpen] = useState(false);
  const [isProjectGroupCreateOpen, setIsProjectGroupCreateOpen] = useState(false);

  const [loadingTasks, setLoadingTasks] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [groups, setGroups] = useState([]);

  const [title, settitle] = useState();
  const [start_date, setstart_date] = useState();
  const [end_date, setend_date] = useState();
  const [description, setdescription] = useState();
  const [color, setcolor] = useState();
  const [repo_name, setrepo_name] = useState();

  const colors = ["#ccc", "#e8384f", "#fd612c", "#fd9a00", "#eec300", "#a4cf30", "#62d26f", "#37c5ab", "#20aaea", "#4186e0", "#7a6ff0", "#aa62e3", "#e362e3", "#ea4e9d", "#fc91ad", "#8da3a6"];

  useEffect(() => {
    async function onRead() {
      setloadingProject(true);

      try {
        const response = await api.get(`projects/detail/${id}`);

        if (!response) throw 'Não foi possível carregar o projeto';
        if (!response.data) throw 'Falha ao ler os dados do projeto';

        const result = response.data;

        if (!result.success) throw result.error;

        settitle(result.data.title);
        setstart_date(result.data.start_date_formatted);
        setend_date(result.data.end_date_formatted);
        setdescription(result.data.description);
        setcolor(result.data.color);
        setrepo_name(result.data.repo_name);

        setEndDate();
        if (result.data.end_date_formatted) {
          setEndDate(parse(result.data.end_date_formatted, 'dd/MM/yyyy', new Date()));
        }

        setloadingProject(false);

        onTasks(id);
      }
      catch (err) {
        toast.error(`${err}`);
        history.push('/dashboard');
      }
    }

    onRead();
  }, [id]);

  async function onTasks(id) {
    setloadingProject(true);

    try {
      const response = await api.get(`projectgroup/filter/${id}`);

      const result = response.data;

      if (!result.success) throw result.error;

      setGroups(result.data);
    }
    catch (err) {
      toast.error(`${err}`);
    }
    finally {
      setloadingProject(false);
    }
  }

  function handleClose() {
    history.push('/projects');
  }

  function handleDelete() {
    dispatch(deleteProjectRequest(id));
  }

  const handleEndDate = date => {
    if (!date) {
      setEndDate();
      setend_date();
      return;
    }
    setEndDate(date);
    setend_date(format(date, 'dd/MM/yyyy'));
  };

  function handleChangeColor(newcolor) {
    if (newcolor !== color) {
      setcolor(newcolor);
      dispatch(projectUpdateRequest(id, title, start_date, end_date, description, newcolor, repo_name, () => { }, false));
    }
    handleToggleColorList();
  }

  function handleToggleColorList() {
    setisColorOpen(!isColorOpen);
  }

  function handleToggleModal() {
    setisModalOpen(!isModalOpen);
  }

  function handleSubmit({ id, title, description }) {
    dispatch(authStartLoading());
    dispatch(projectUpdateRequest(id, title, start_date, end_date, description, color, repo_name, handleToggleModal));
  }

  return (
    <Container>
      <Header>
        <Details>
          <FaCircle style={{ 'color': color }} onClick={handleToggleColorList} />
          <ColorListContainer style={{ display: isColorOpen ? 'block' : 'none' }} onClick={handleToggleColorList} />
          <ColorList style={{ display: isColorOpen ? 'grid' : 'none' }}>
            {colors.map(item => (
              <li key={item}>
                <FaCircle
                  style={{ 'color': item }}
                  className={item === color ? 'active' : ''}
                  onClick={() => handleChangeColor(item)}
                />
              </li>
            ))}
          </ColorList>
          <h1>{title}</h1>
          <Buttons>
            <MdEdit title="Editar projeto" onClick={handleToggleModal} />
          </Buttons>
        </Details>
        <Buttons>
          <MdDelete title="Excluir projeto" onClick={() => { if (window.confirm('Deseja realmente excluir este projeto?')) handleDelete() }} />
          <MdClose title="Fechar" onClick={handleClose} />
        </Buttons>
      </Header>
      <Body>
        {loadingProject ?
          (
            <Loading>
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </Loading>
          ) : (
            <TaskList>
              <thead>
                <tr>
                  <th style={{ width: '20px' }}></th>
                  <th>Tarefa</th>
                  <th>Responsável</th>
                  <th>Prazo</th>
                </tr>
              </thead>
              <tbody>
                {loadingTasks ?
                  (
                    <tr>
                      <td colSpan="4">
                        <Loading>
                          <div className="rect1"></div>
                          <div className="rect2"></div>
                          <div className="rect3"></div>
                          <div className="rect4"></div>
                          <div className="rect5"></div>
                        </Loading>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {groups.length <= 0 ? (
                        <tr>
                          <td colSpan={4}>Nenhuma tarefa encontrada.</td>
                        </tr>
                      ) : (
                          <>
                            {groups.map(g => (
                              <>
                                <tr key={g.id} className="group">
                                  <td>::</td>
                                  <td colSpan={3}>{g.title}</td>
                                </tr>
                                {g.Tasks.map(t => (
                                  <tr key={`${g.id}_${t.id}`}>
                                    <td>::</td>
                                    <td>{t.title}</td>
                                    <td></td>
                                    <td>{t.end_date_formatted}</td>
                                  </tr>
                                ))}
                              </>
                            ))
                            }
                          </>
                        )
                      }
                    </>
                  )
                }
              </tbody>
            </TaskList>
          )
        }
      </Body>

      <div className="btn-fixed">
        <button type="button" className="btn btn-danger rounded-circle" onClick={() => setIsProjectGroupCreateOpen(true)}>
          <MdAdd />
        </button>
      </div>

      <Modal show={isModalOpen}
        onClose={handleToggleModal}
        title="Editar projeto">
        <ModalContent>
          <Form schema={schema} onSubmit={handleSubmit} initialData={{
            title: title,
            start_date: start_date,
            end_date: end_date,
            description: description,
            repo_name: repo_name
          }}>
            <Input name="id" type="hidden" value={id} />

            <FormGroup>
              <label htmlFor="title">Nome do projeto</label>
              <Input name="title" type="text" placeholder="Título do projeto" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="endDate">Data da entrega</label>
              <DatePicker
                id="endDate"
                selected={endDate}
                onChange={handleEndDate}
                dateFormat="dd/MM/yyyy"
                autoComplete="off"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="description">Descrição</label>
              <Textarea name="description" placeholder="Descrição do projeto" rows={10} />
            </FormGroup>

            <FormGroup>
              <button className="btn" type="submit" disabled={loading}>{loading ? 'Carregando...' : 'Salvar'}</button>
            </FormGroup>
          </Form>
        </ModalContent>
      </Modal>

      <ProjectGroupCreate
        isOpen={isProjectGroupCreateOpen}
        loading={loading}
        onClose={() => setIsProjectGroupCreateOpen(false)}
        onRefresh={() => onTasks(id)}
        projectId={id}
      />
    </Container >
  );
}
