import styled, { css, keyframes } from 'styled-components';
import { lighten } from 'polished';

const stretchdelay = keyframes`
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  max-width: 100%;
`;

export const Loading = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 10px;

  &> div {
    margin: 1px;
    background-color: #4b646f;
    height: 80%;
    width: 5px;
    display: inline-block;
    
    -webkit-animation: ${stretchdelay} 1.2s infinite ease-in-out;
    animation: ${stretchdelay} 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ccc;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    padding: 2px;
    font-size: 18px;
  }

  svg {
    margin-right: 10px;
    font-size: 18px;
    cursor: pointer;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
    font-size: 24px;
    color: ${lighten(0.3, '#4b646f')};
    cursor: pointer;
    &:hover {
      color: ${lighten(0.5, '#4b646f')}
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`;

export const TaskList = styled.table`
  width: 100%;
  margin: 0 auto;

  th, td {
    padding: 5px 10px 5px 10px;
    text-align: left;
  }

  tr {
    &.group {
      td + td {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  form {
    width: 100%;
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    margin: 0 -15px 0 -15px;
    border-top: 1px solid #ccc;
    text-align: right;
  }
`;

export const FormGroup = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px 15px 15px;

  label {
    color: #4b646f;
    text-align: left;
    margin-bottom: 5px;
    width: 100%;
  }

  input, select, textarea, button {
    width: 100%;
    margin: 0;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const ColorList = styled.ul`
  position: absolute;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  grid-gap: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #fff;
  margin-top: 90px;
  padding: 10px;

  li {

    svg {
      font-size: 26px;
      border: 2px solid #fff;
      margin-right: 0;

      &.active {
        border-color: #b8c7ce;
      }
    }
  }
`;

export const ColorListContainer = styled.div`
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
`;