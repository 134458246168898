import styled, { keyframes, css } from 'styled-components';
import { FaCogs } from 'react-icons/fa';

const menuShow = keyframes`
  from {
    left: -300px;
  }

  to {
    left: 0;
  }
`;

const menuHide = keyframes`
  from {
    left: 0;
  }

  to {
    left: -300px;
  }
`;

const stretchdelay = keyframes`
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
`;

export const Container = styled.div`
  position: relative;
  background-color: #222d32;
  width: 250px;
  height: calc(100% - 64px);
  float: left;

  @media (max-width: 992px) {
    left: -300px;
    width: 300px;
    margin-right: -300px;
    ${props => css`
      left: ${props.open ? '0' : '-300px'};
      animation: ${props.open ? menuShow : menuHide} 0.3s ease-in;
    `}
  }
`;

export const MobileList = styled.ul`
  display: none;
  
  @media (max-width: 992px) {
    display: flex;
  }

  list-style: none;
  width: 100%;
  flex-direction: column;
`;

export const List = styled.ul`

  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Separator = styled.li`
  color: #4b646f;
  background: #1a2226;
  overflow: hidden;
  text-overflow: clip;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 10px 25px 10px 15px;
  font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  margin: 0;
`;

export const ListItem = styled.li`
  display: flex;
  color: #ccc;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #222;
  cursor: pointer;

  a {
    padding: 12px 10px 12px 15px;
    display: flex;
    border-left: 3px solid transparent;
    color: #b8c7ce;
    font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.42857143;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }

    svg {
      font-size: 14px;
      margin-right: 10px;
      color: #b8c7ce;
    }

    span {
      color: #b8c7ce;
    }
  }

  &:hover {
    background: #1e282c;

    a {
      border-left: 3px solid #ff9800;
      color: #fff;

      span, svg {
        color: #fff;
      }
    }
  }
`;

export const BtnProjects = styled(FaCogs).attrs({
  size: 15
})`
  float: right;
  color: #4b646f;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

export const Loading = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;

  &> div {
    margin: 1px;
    background-color: #b8c7ce;
    height: 80%;
    width: 3px;
    display: inline-block;
    
    -webkit-animation: ${stretchdelay} 1.2s infinite ease-in-out;
    animation: ${stretchdelay} 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
`;
