import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { store } from '~/store';

import { menuChangeState } from '~/store/modules/menu/actions';

import { Container, Content, Profile, MenuMobile } from './styles';

export default function Header() {
  const dispatch = useDispatch();

  const { id, name } = store.getState().user.profile;
  const menuOpen = useSelector(state => state.menu.open);

  function handleMenuMobile() {
    dispatch(menuChangeState(!menuOpen));
  }

  return (
    <Container>
      <Content>
        <nav>
          <Link to="/dashboard">Station Up</Link>
          <MenuMobile onClick={handleMenuMobile} />
        </nav>

        <aside>
          <Profile>
            <div>
              <strong>{name}</strong>
              <Link to="/profile">Meu perfil</Link>
            </div>
            <img
              src={`https://api.adorable.io/avatars/50/abott@adorable-${id}.png`}
              alt={name}
            />
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
