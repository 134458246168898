import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  max-width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;

  h1 {
    font-size: 18px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
    font-size: 24px;
    color: ${lighten(0.3, '#4b646f')};
    cursor: pointer;
    &:hover {
      color: ${lighten(0.5, '#4b646f')}
    }
  }
`;

export const Body = styled.div`
  margin: auto;
  width: 100%;
  max-width: 400px;
  padding: 15px;

  form {
    width: 100%;
  }
`;

export const FormGroup = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px 15px 15px;

  label {
    color: #4b646f;
    text-align: left;
    margin-bottom: 5px;
    width: 100%;
  }

  input, select, textarea, button {
    width: 100%;
    margin: 0;
  }
`;
