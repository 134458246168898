import React from 'react';
import { Switch } from 'react-router-dom';
import Route from '~/routes/Route';

import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';

import Dashboard from '~/pages/Dashboard';
import Profile from '~/pages/Profile';
import Project from '~/pages/Project';
import ProjectCreate from '~/pages/ProjectCreate';
import ProjectDetail from '~/pages/ProjectDetail';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" component={SignUp} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/projects" exact component={Project} isPrivate />
      <Route path="/projects/new" component={ProjectCreate} isPrivate />
      <Route path="/projects/:id" component={ProjectDetail} isPrivate />
    </Switch>
  );
}
