import { createGlobalStyle } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import '~/assets/css/common/bootstrap.scss';
import '~/assets/css/common/responsive.scss';
import '~/assets/css/common/buttons.scss';
import '~/assets/css/common/card.scss';
import '~/assets/css/common/table.scss';
import '~/assets/css/common/modal.scss';

import bg from '~/assets/bg.jpg';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Kaushan+Script&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    &:focus{
      outline: 0;
    }
  }

  html, body, #root {
    height: 100%;
  }

  body{
    -webkit-font-smoothing: antialiased !important;
    background: url(${bg}) center center no-repeat;
    background-size: cover;
  }

  body, button, input, textarea, select {
    font: 14px 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }
`;