import produce from 'immer';

const INITIAL_STATE = {
  open: false
};

export default function menu(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@menu/CLICK':
        draft.open = action.open;
        break;

      default:
    }
  });
}
