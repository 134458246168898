import { combineReducers } from 'redux';

import auth from './auth/reducer';
import menu from './menu/reducer';
import user from './user/reducer';
import projects from './projects/reducer';

export default combineReducers({
  auth,
  menu,
  user,
  projects
});