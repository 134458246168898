import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MdClose } from 'react-icons/md';

import { Container, Content, ModalHeader, ModalBody, ModalFooter } from './styles';

class Modal extends Component {
  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Container>
        <Content className={this.props.size === 'xlarge' ? 'xl' : this.props.size === 'large' ? 'lg' : 'md'}>
          <ModalHeader>
            <h1>{this.props.title}</h1>
            <MdClose title="Fechar" onClick={this.props.onClose} />
          </ModalHeader>
          <ModalBody>
            {this.props.children}
          </ModalBody>
        </Content>
      </Container>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node
};

export default Modal;
