import styled from 'styled-components';

export const Container = styled.div`
  form {
    width: 100%;
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    margin: 0 -15px 0 -15px;
    border-top: 1px solid #ccc;
    text-align: right;
  }
`;
