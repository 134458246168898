import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaPlus, FaCircle } from 'react-icons/fa';

import { menuChangeState } from '~/store/modules/menu/actions';
import { projectsRead, projectAddRequest } from '~/store/modules/projects/actions';

import { Container, List, ListItem, Separator, BtnProjects, Loading } from './styles';

export default function Projects() {
  const dispatch = useDispatch();

  const menuOpen = useSelector(state => state.menu.open);
  const projects = useSelector(state => state.projects);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(projectsRead());
    setLoading(true);
  }, []);

  useMemo(() => {
    setLoading(false);
  }, [projects]);

  function handleMenuMobile() {
    dispatch(menuChangeState(!menuOpen));
  }

  function handleAddProject() {
    dispatch(projectAddRequest('Meu novo projeto'));
    dispatch(menuChangeState(!menuOpen));
  }


  return (
    <Container open={menuOpen}>
      <List>
        <Separator>
          Projetos
          <BtnProjects />
        </Separator>
        {loading ?
          (
            <ListItem>
              <Loading>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
              </Loading>
            </ListItem>
          ) :
          (
            <ListItem>
              <Link to="/projects/new" onClick={handleMenuMobile}>
                <FaPlus />
                <span>Novo projeto</span>
              </Link>
            </ListItem>
          )
        }
        {projects.map(project => project.id && (
          <ListItem key={project.id} onClick={handleMenuMobile}>
            <Link to={`/projects/${project.id}`}>
              <FaCircle style={{ 'color': project.color }} />
              <span>{project.title}</span>
            </Link>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
